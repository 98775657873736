import Big from 'big.js';

export const roundAndConvertDecimalToBigInt = (
  value: number | string | undefined | null,
) => (value ? BigInt(Big(value.toString()).mul(100).round().toNumber()) : 0n);

export const convertNumberToStringBigInt = (value: number) => `${value * 100}`;

export function convertBigIntToDecimal(value: bigint): number;
export function convertBigIntToDecimal(value: undefined): undefined;
export function convertBigIntToDecimal(
  value: bigint | undefined,
): number | undefined;
export function convertBigIntToDecimal(
  value: bigint | undefined,
): number | undefined {
  return value ? Big(value.toString()).div(100).toNumber() : undefined;
}
