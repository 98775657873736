enum Routes {
  // /////
  // identity
  // TODO : change URL to respect ACTIVE-20240807-route-naming
  // ////
  login = '/login',
  loginEmailValidation = '/login/validate-email',

  validateEmail = '/validate-email',
  validateEmailExpiredToken = '/validate-email/expired-token',

  myProfile = '/my-profile',
  editProfile = '/my-profile/edit-profile',
  profileConnectionSecurity = '/my-profile/connection-security',
  sampleFormUploadFile = '/form/upload-file',
  sampleRenderError = '/render-error',

  askPasswordReset = '/ask-password-reset',
  resetPassword = '/reset-password',

  newUser = '/new-user',

  mfa = '/my-profile/connection-security/mfa',
  mfaTotpSetup = '/my-profile/connection-security/mfa/setup/provider/totp',
  mfaEmailSetup = '/my-profile/connection-security/mfa/setup/provider/email',
  mfaEmailSetupCodeValidation = '/my-profile/connection-security/mfa/setup/validate/email',
  mfaRecoveryCodeSetup = '/my-profile/connection-security/mfa/setup/show-recovery-code',
  mfaRecoveryCodeSetupValidation = '/my-profile/connection-security/mfa/setup/validate/recovery-code',
  mfaVerifyEmail = '/mfa/verify/email',
  mfaVerifyTotp = '/mfa/verify/totp',
  mfaVerifyRecoveryCodes = '/mfa/verify/recovery-code',
  mfaEmailConfirmDelete = '/my-profile/connection-security/mfa/confirm-delete/email',
  mfaTotpConfirmDelete = '/my-profile/connection-security/mfa/confirm-delete/totp',

  // /////
  // antor
  // ////

  Place = '/places',
  Dashboard = '/dashboard',
  PlaceIdVenue = '/places/:placeId/venues',

  // Series
  Series = '/series',
  SeriesCreate = '/series/create',
  SeriesId = '/series/:seriesId',
  SeriesIdPricingGrid = '/series/:seriesId/pricing-grids',
  SeriesIdSellingChannel = '/series/:seriesId/selling-channels',
  SeriesIdOrganizations = '/series/:seriesId/organizations',
  SeriesIdTicketsFormat = '/series/:seriesId/tickets-format',
  SeriesIdManifestations = '/series/:seriesId/manifestations',
  SeriesIdManifestationsId = '/series/:seriesId/manifestations/:manifestationId',
  SeriesIdManifestationsIdSellingChannel = '/series/:seriesId/manifestations/:manifestationId/selling-channels',
  SeriesIdManifestationsIdPricingGrid = '/series/:seriesId/manifestations/:manifestationId/pricing-grids',
  SeriesIdManifestationsIdTicketsFormat = '/series/:seriesId/manifestations/:manifestationId/tickets-format',
  SeriesIdManifestationsIdShows = '/series/:seriesId/manifestations/:manifestationId/shows',

  // Organizations
  Organizations = '/organizations',
  OrganizationsIdUsers = '/organizations/:organizationId/users',
  OrganizationsIdManage = '/organizations/:organizationId/manage',
  OrganizationInvitation = '/organization-invitations/:token',

  // API Configuration
  ApiConfigurationSellingChannels = '/configuration-api/selling-channels',
}

export default Routes;
