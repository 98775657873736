import { z } from 'zod';

import { convertNumberToStringBigInt } from '@/lib/convert';

export const stringSchema = z
  .string({
    required_error: 'error.requiredField',
    invalid_type_error: 'error.invalidInput',
  })
  .trim();

export const stringRequiredSchema = stringSchema.min(1, 'error.requiredField');

export const loginRequiredSchema = stringRequiredSchema.email(
  'auth.error.invalidLogin',
);

export const emailSchema = stringSchema.email('auth.error.invalidEmail');

export const numberSchema = z.number({
  required_error: 'error.requiredField',
  invalid_type_error: 'error.invalidInput',
});

export const dateSchema = z.date({
  required_error: 'error.requiredField',
  invalid_type_error: 'error.invalidInput',
});

export const numberPositiveSchema = numberSchema.positive();

export const numberGreaterThanZeroSchema = numberSchema.min(0);

export const numberPositiveToBigIntSchema = numberPositiveSchema.transform(
  (val) => convertNumberToStringBigInt(val),
);

export const numberToPercent = numberPositiveSchema.transform(
  (val) => val / 100,
);

export const intPositiveSchema = numberPositiveSchema.int().min(1);

export const insensitiveUniqueStringArray = z
  .array(stringRequiredSchema)
  .refine(
    (items) =>
      new Set(items.map((item) => item.toLowerCase().trim())).size ===
      items.length,
    {
      message: 'error.invalidArrayValuesUniqueness',
    },
  );
